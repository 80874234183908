.auth-card
  background-color: $matik-white
  border: 1px solid $grey-300
  border-radius: 4px
  box-shadow: none

  .input
    height: 45px
    line-height: 45px

  .has-icons-left
    .icon
      height: 100%
      color: $grey-400

  .forgot-password
    display: flex
    flex-direction: column
    align-self: center
    text-align: center

.login-background
  background-color: $grey-100
  background-size: cover

.auth-logo
  display: flex
  flex-direction: column
  justify-content: center
  padding-bottom: 2rem

  img
    height: 50px
    display: flex

.signup-logo
  display: flex
  flex-direction: column
  justify-content: center
  height: 40px
  padding-top: 4rem
  padding-bottom: 4.5rem

  img
    height: 40px

.signup-card
  display: block
  margin-left: auto
  margin-right: auto
  width: 990px
  height: 584px
  background-color: $input-background-color
  border-radius: 8px
  box-shadow: 0px 3px 10px rgba($matik-black, 0.08), 0px 14px 28px rgba($matik-black, 0.08)
  overflow: hidden

  .signup-header
    color: $matik-black
    font-weight: 600
    font-size: 22px
    line-height: 24px
    position: absolute
    top: 0
    left: 0
    padding-top: 3rem
    padding-left: 4rem

  .signup-form
    width: 495px
    padding-top: 0
    padding-left: 4rem
    padding-right: 4rem

    .signup-input:focus
      border: 1px solid $matik-green
      box-shadow: 0 0 0 0.125em $green-100

    .agree-to-terms
      font-weight: 10
      font-size: 14px
      line-height: 140%
      color: $grey-700

    .underline
      text-decoration: underline

    .signup-submit
      font-size: 16px
      line-height: 16px

  .have-account
    color: $grey-700
    text-align: center

    .signup-login
      color: $grey-700
      text-decoration: underline

.default-signup-background
  background-image: url('../../images/signup_background.png')
.qbr-one-pager-signup-background
  background-image: url('../../images/qbr-one-pager-signup.png')

.matik-auth-plug
  width: 495px
  height: 584px
  background-size: cover
  text-align: left

  .matik-signup-text
    padding-top: 3rem
    padding-left: 4rem
    padding-right: 3rem
    color: $white
    font-style: normal
    font-weight: 600
    font-size: 36px
    line-height: 110%

  .footer-links
    position: absolute
    bottom: 0px
    padding-bottom: 4rem
    padding-left: 4rem

    .footer-link
      color: $white
      padding-right: 1rem
      font-size: 14px
      line-height: 18px

.f-underline
  display: inline
  position: relative
  background-position: 0 95%
  background-size: 20px 10px
  background-repeat: repeat-x

.f-underline-green
  background-image: linear-gradient($matik-green, $matik-green)

.forgot-captcha
  display: flex
  justify-content: center

.signup-trial
  display: flex
  flex-direction: row
  flex-wrap: wrap
  width: 100%
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0

  .signup-trial-left,
  .signup-trial-right
    display: flex
    flex-direction: column
    flex: 1
    align-items: center
    padding-top: 80px

  .signup-trial-left
    align-items: center
    .signup-container
      width: 560px
    .signup-container:first-child
      display: flex
      flex-direction: column
      justify-content: flex-end
      margin-bottom: 1rem
    .divider
      width: 100%
      border-bottom: 1px solid $grey-300
      margin-bottom: 1rem
    .signup-trial-logo
      width: 110px
      height: 40px
      img
        height: 26px
        padding-right: 1rem
        margin-right: 1rem
        border-right: 1px solid $grey-300
    .signup-container-header
      position: relative
    .signup-trial-header
      font-size: 22px
    .signup-container-header
      .signup-trial-header
        position: absolute
        top: -3px
    .signup-input:focus
      border: 1px solid $matik-green
      box-shadow: 0 0 0 0.125em $green-100

    .agree-to-terms
      font-weight: 10
      font-size: 14px
      line-height: 140%
      color: $grey-600

      .underline
        text-decoration: underline
        color: $grey-600

    .signup-submit
      font-size: 16px
      line-height: 16px

    .have-account
      color: $grey-700
      text-align: center

      .signup-login
        color: $grey-700
        text-decoration: underline

    .button-style
      padding-top: 40px
      width: 560px
      .branding-generate
        background-color: #0D1C53
        color: white
        border: 40px
        width: 100%


  .signup-trial-right
    background: linear-gradient(315deg, #0E1D53 24.4%, #7869CC 91.41%, #53EBAD 125.6%)
    overflow: hidden

    .matik-auth-plug-trial
      width: 560px
      height: 100%
      text-align: center
      background: $matik-white
      border-top-right-radius: 10px
      border-top-left-radius: 10px
      position: relative

      .matik-signup-text
        color: $matik-blue
        font-style: normal
        font-weight: 600
        font-size: 36px
        line-height: 120%
        width: 460px
        margin: 0 auto

      .is-secondary-description
        width: 460px
        margin: 0 auto
        line-height: 150%

      .default-company-logo
        width: 36px
        padding-top: 1.25rem
        padding-bottom: 0.5rem
        margin: 0 auto
        margin-bottom: 1.5rem
        border-bottom: 1px solid $matik-green

      .image-container
        overflow: hidden
        img
          width: 475px
          position: absolute
          top: 400px
          left: calc(50% - 237.5px)

        .gif-styling
          width: 75%
          padding-left: 40px

        .scanning-metrics
          font-style: italic

.branding-step
  z-index: 105
  background-color: $matik-white

