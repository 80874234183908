.query-grandparent
  display: flex
  flex-direction: column

  .query-parent
    flex: 1
    display: flex
    flex-direction: column

.dynamic-content-info
  flex-direction: column
  align-items: center
  text-align: center
  padding: 3rem 4rem

.param-modal
  width: 800px

.param-limited-width
  max-width: 180px

.param-medium-width
  width: 210px

.param-long-width
  width: 280px

.api-checkbox
  width: 80px

.param-select
  select
    color: $yellow-500

.single-param
  width: 400px

.param-form
  border: 1px solid #ddd

.lined-form
  & > .field
    border-bottom: 1px solid $grey-300

.entity-list-button-container
  background: linear-gradient(180deg, rgba($matik-white, 0) 0%, $matik-white 100%)
  width: 25%
  position: absolute
  bottom: 0
  padding: 1.5rem
  display: flex
  justify-content: flex-end

button.icon-button, div.icon-button
  background-color: transparent
  border: 0
  cursor: pointer
  height: 24px
  &:hover
    opacity: 0.8
  &:disabled
    svg,path,rect
      stroke: $grey-400


.button-disabled
  cursor: not-allowed !important

.query-result
  min-height: 10rem
  overflow-x: scroll
  max-width: calc(100vw - 31rem)

.sidepane-query-result
  min-height: 10rem
  max-width: calc(100vw - 68rem)
  min-width: 638px
  overflow-x: scroll

.bottom-buttons
  background: linear-gradient(180deg, rgba($matik-white, 0) 0%, $grey-100 100%)
  position: absolute
  right: 24px
  bottom: 0
  pointer-events: none
  z-index: 11
  &.full-width
    width: 100%
  &.no-background
    background: none
  .buttons
    display: flex
    justify-content: flex-end
    margin-bottom: 1.5rem
    .button
      pointer-events: all

.tab-buttons
  width: calc(100% - 440px)

.dynamic-content-list
  border-radius: 4px
  &.data-dictionary
    height: calc(100vh - 19.4rem)

.content-type-with-delete
  @extend .is-medium
  @extend .matik-tag
  @extend .is-rounded
  .delete-button
    @extend .is-small
    @extend .has-text-grey-dark
    @extend .is-flex
    @extend .pls
    height: 12px

.header-wrapper
  padding: 0.5rem 0.5rem 0 0.5rem
  border-bottom: 1px solid $grey-300

.type-selector
  border-top: 1px solid $grey-300
  border-left: 1px solid $grey-300
  border-right: 1px solid $grey-300
  background-color: $matik-white
  .tabs
    ul
      border-bottom: 1px solid $grey-300
      li:first-child
        a, span
          border-left: 0
        &.is-active
          a, span
            border-left: 0
      li
        a, span
          border-right: 1px solid transparent
          border-left: 1px solid transparent
          border-bottom: 1px solid $grey-300
        &.is-active
          background-color: $grey-100
          a
            border-right: 1px solid $grey-300
            border-left: 1px solid $grey-300
            border-bottom: 1px solid $matik-green
          span
            border-right: 1px solid $grey-300
            border-left: 1px solid $grey-300
        &:hover a
          border-bottom: 1px solid $matik-green
    span
      align-items: center
      display: flex
      justify-content: center
      margin-bottom: -1px
      padding: 0.5em 1em
      vertical-align: top

.content-type
  cursor: pointer
  flex-direction: column
  align-items: center
  border-radius: 4px
  border: 1px solid $grey-300

.rest-results-button
  border: 1px solid $grey-300
  border-radius: 4px
  border-top-right-radius: 0
  border-bottom-right-radius: 0
  height: 36px
  .button
    background-color: $grey-100
    color: $matik-black
    height: 34px

.rest-results-input
  min-width: 126px
  .input-like
    height: 36px !important
    padding-top: 6px !important
    border-top-left-radius: 0 !important
    border-bottom-left-radius: 0 !important

.hero .dynamic-content-form-wrapper
  overflow-y: scroll

.form-header
  height: 10rem
  background-color: $matik-white
  margin: 0 -1rem
  &.is-new
    max-height: 5rem
  .template-preview
    border-bottom: 1px solid $grey-300
    padding: 2px
    margin: 2px 0 10px 2px
    .template-preview-header
      display: flex
      justify-content: space-between
      height: 25px
      .slide-count
        border: 1px solid $grey-300
        border-radius: 32px
        padding: 2px 2px 3px 2px
        margin-top: 20px
        height: 25px
        width: 40px
        background-color: $matik-white
        z-index: 2
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
    .slides-thumbnails
      display: flex
      margin: 0.4rem 0 2.5rem 0
      &.is-full
        justify-content: space-between
      .connected-item-slide
        width: 6rem
        height: 3.5rem
        border: 1px solid $grey-300
        &.sub-quad
          margin-right: 0.9rem
        &:hover
          border: 1px solid $matik-green
          cursor: pointer
  .osteo-quad
    display: flex
    justify-content: space-between
  .osteo-sub-quad
    display: flex
    justify-content: flex-start

.form-header-top-row
  border-bottom: 1px solid $grey-300
  padding-bottom: 1.25rem
  margin-bottom: 1rem !important
  .level-left
    flex-shrink: 1
    min-width: 0
    padding-right: 1rem
    max-height: 5rem
    .level-item:last-child
      flex-shrink: 1
      min-width: 0

.form-header-bottom-row
  padding: 0 2rem

.select-content-type
  position: relative
  cursor: pointer
  padding-left: 1rem

.select-content
  position: absolute
  border: 1px solid $grey-300
  top: 3.5rem
  left: 1rem
  width: 10rem
  padding: 5px 0 !important
  z-index: 1

.connected-items-popup
  position: relative
  z-index: 5
  margin: -1.5rem 0 0 1rem
  max-height: 65vh
  width: 54%
  min-width: 30rem
  max-width: 25vw
  overflow-y: auto
  border: 1px solid $grey-300
  padding: 1rem

.dc-form-main
  &.is-new
    min-height: calc(100vh - 57px)

.link-heading
  display: inline-block

.content-card-container
  display: flex
  justify-content: space-between
  width: 100%
  flex-flow: column

.content-select-close-buttons
  display: flex
  justify-content: space-between
.list-close-icon-left
  position: relative
  left: 0
  top: 3rem
  z-index: 1
.list-close-icon-right
  position: relative
  right: 0
  top: 3rem
  z-index: 1

.form-skeleton-loader
    height: calc(100vh - 3.5rem - 1px)
    padding: 1.25rem 0.25rem 0 0.25rem
    background-color: $matik-white
    width: 100%
    .skeleton-loader-header
      height: 5rem
      padding: 0.25rem 0.25rem 1rem 1rem
      margin-right: 1rem
      border-bottom: 1px solid $grey-300
    .skeleton-loader-sub-header
      height: 5rem
      padding: 1rem
    .skeleton-loader-body
      height: 60%
      padding: 1rem
    .skeleton-loader-footer
      height: 10rem
      padding: 1rem

.salesforce-search
    &::placeholder
        color: $grey-600

.insights-context
    &::placeholder
        color: $grey-600

.salesforce-search-container
  display: flex
  align-items: center
  gap: 0.75rem

.fullscreen-modal-dynamic-content
  padding: 2rem
  padding-left: 6rem
  padding-right: 6rem
  display: flex
  min-width: 100%

.bottom-buttons-fs
  background: linear-gradient(180deg, rgba($matik-white, 0) 0%, $grey-100 100%)
  display: flex
  justify-content: flex-end
  z-index: 11

.dynamic-content-form-fullscreen
  width: 100%

.query-explainer-container
  position: absolute
  z-index: 10
  bottom: 10px
  right: 20px
